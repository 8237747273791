export default class CacheableForms {
  /**
   * @property array carousels
   *
   */
  forms = {}
  /**
   * @property EventBus #eventBus
   */
  #eventBus

  constructor(eventBus) {
    this.#eventBus = eventBus
    this.bind()
  }

  bind() {
    this.#eventBus.on('form:refresh', (config) => this.refreshForm(config))
  }

  /**
   * Refresh a cached form with the latest state from the server
   * @param el
   * @param handle
   * @returns {Promise<void>}
   */
  async refreshForm({ el, handle, template }) {
    const form = document.querySelector(el)

    if (form) {
      try {
        this.forms[el] = form
        const response = await fetch(`/dynamic/form?${new URLSearchParams({ 
          f: handle,
          t: template,
        })}`)
        if (response.ok) {
          this.updateForm(form, await response.json())
        } else {
          throw new Error(`Non-200 response for form ${handle}`)
        }
      } catch (e) {
        console.error(`Error refreshing form ${handle}`, e)
      }
    }
  }

  /**
   * Update the form with the new values from the dynamic form response
   *
   * @param form
   * @param honeypot
   * @param csrf
   * @param hash
   * @param payload
   */
  updateForm(form, {
    honeypot,
    csrf,
    hash,
    payload,
  }) {
    // Update the Form Hash
    form.querySelector('input[name=formHash]').value = hash

    // Update the Payload if encrypted payloads are enabled
    form.querySelector('input[name=freeform_payload]').value = payload

    // Update the JS Honeypot (ONLY when JS Enhancement setting is ON)
    const honeypotInput = form.querySelector('input[name^="freeform_form_handle"]')
    if (honeypotInput) {
      honeypotInput.setAttribute('id', honeypot.name)
      honeypotInput.setAttribute('name', honeypot.name)
      honeypotInput.value = honeypot.hash
    }
    // Locate and update the CSRF input
    form.querySelector('input[name=' + csrf.name + ']').value = csrf.value
  }
}
