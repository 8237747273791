import Splide from '@splidejs/splide';

export default class Carousels {
  /**
   * @property array carousels
   *
   */
  instances = {}
  /**
   * @property EventBus #eventBus
   */
  #eventBus

  constructor(eventBus) {
    this.#eventBus = eventBus
    this.bind()
  }

  bind() {
    this.#eventBus.on('carousels:init', (config) => this.initCarousel(config))
  }

  initCarousel({el, options = {}}) {
    this.instances[el] = new Splide(el, {
      pagination: false,
      ...options
    }).mount();
  }
}
